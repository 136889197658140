import { Box, Typography } from "@mui/material";
import { ReactElement, ReactNode } from "react";


interface StepHeaderProps {
  title?: string;
  text?: string | ReactNode;
}

const StepHeader = ({ title, text }: StepHeaderProps): ReactElement => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
    {title && (
      <Typography variant="h1" fontSize={56} lineHeight={1} textAlign="center">
        {title}
      </Typography>
    )}
    {text && (
      <Typography variant="body1" fontSize={20} textAlign="center" maxWidth={765}>
        {text}
      </Typography>
    )}
  </Box>
);

export default StepHeader;
